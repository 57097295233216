import _color_map from "./color_map.json";
import _location_map from "./location_map.json";

export enum LOCATION_MAP_TYPE {country = "country", continent = "continent", others = "others"}

export enum LOCATION_MAP_REGION {
    "Eastern Africa" = "Eastern Africa", "Western Africa" = "Western Africa",
    "Northern Africa" = "Northern Africa", "Middle Africa" = "Middle Africa", "Southern Africa" = "Southern Africa",
    "Central Asia" = "Central Asia", "Eastern Asia" = "Eastern Asia", "Southern Asia" = "Southern Asia",
    "South-eastern Asia" = "South-eastern Asia", "Western Asia" = "Western Asia", "Eastern Europe" = "Eastern Europe",
    "Northern Europe" = "Northern Europe", "Southern Europe" = "Southern Europe", "Western Europe" = "Western Europe",
    "Northern America" = "Northern America", "Central America" = "Central America", "Caribbean" = "Caribbean",
    "South America" = "South America", "Melanesia" = "Melanesia", "Australia and New Zealand" = "Australia and New Zealand",
    "Polynesia" = "Polynesia", "Micronesia" = "Micronesia", "Antarctica" = "Antarctica", "NA" = "NA"
}

export enum LOCATION_MAP_CONTINENT {"Africa" = "Africa", "Asia" = "Asia", "Europe" = "Europe", "North America" = "North America", "Oceania" = "Oceania", "South America" = "South America", "NA" = "NA"}

const COLOR_MAP: { [key: string]: string } = _color_map;
const LOCATION_MAP: {
    type: { [key: string]: string },
    region: { [key: string]: string },
    continent: { [key: string]: string }
} = _location_map;

export interface Datum {
    date: string,
    location: string,
    new_cases: number,
    new_deaths: number,
    total_cases: number,
    total_deaths: number,
    weekly_cases: number,
    weekly_deaths: number,
    biweekly_cases: number,
    biweekly_deaths: number
}

export type Data = Array<Datum>;

export interface RawDatum {
    date: string,
    location: string,
    new_cases?: string,
    new_deaths?: string,
    total_cases?: string,
    total_deaths?: string,
    weekly_cases?: string,
    weekly_deaths?: string,
    biweekly_cases?: string,
    biweekly_deaths?: string
}

export interface TransformedDatum {
    location: string,
    date: string,
    total_cases: number,
    total_deaths: number,
    case_fatality_rate: number,
    _region: string,
    _color: string,
}

export class DataStore {
    rawData: Data;

    constructor(rawData: Array<RawDatum>) {
        this.rawData = this.typeTransform(rawData);
    }

    private typeTransform = (rawData: Array<RawDatum>): Array<Datum> => {
        return rawData.map(x => ({
            date: x.date,
            location: x.location,
            new_cases: parseInt(x.new_cases || "0"),
            new_deaths: parseInt(x.new_deaths || "0"),
            total_cases: parseInt(x.total_cases || "0"),
            total_deaths: parseInt(x.total_deaths || "0"),
            weekly_cases: parseInt(x.weekly_cases || "0"),
            weekly_deaths: parseInt(x.weekly_deaths || "0"),
            biweekly_cases: parseInt(x.biweekly_cases || "0"),
            biweekly_deaths: parseInt(x.biweekly_deaths || "0"),
        }))
    }

    get latestDayData(): Array<TransformedDatum> {
        let dataByLocation: { [key: string]: TransformedDatum } = {};
        this.rawData.forEach((datum: Datum) => {
            if (dataByLocation?.[datum.location] == null ||
                (dataByLocation?.[datum.location] && dataByLocation?.[datum.location].date < datum.date)) {
                dataByLocation[datum.location] = {
                    location: datum.location,
                    date: datum.date,
                    total_cases: datum.total_cases,
                    total_deaths: datum.total_deaths,
                    case_fatality_rate: datum.total_deaths / datum.total_cases * 100,
                    _region: (LOCATION_MAP?.["region"]?.[datum.location] as string) || "NA",
                    _color: (COLOR_MAP?.[(LOCATION_MAP?.["region"]?.[datum.location] as string)] as string) || COLOR_MAP["Others"] || "#000000",
                }
            }
        });
        let values = Object.values(dataByLocation);
        console.log('[Data-latestDayData] latestDayData', values);
        return values;
    }

    get rawDataColumns(): Array<string> {
        return Object.keys(this.rawData?.[0] || {});
    }

    get transformedDataColumns(): Array<string> {
        return ["location", "date", "total_cases", "total_deaths", "case_fatality_rate", "_region"];
    }

    get rawDataAllDate(): Array<string> {
        let s = new Set(this.rawData.map(x => x.date));
        return Array.from(s).sort();
    }

    get allLocations(): Array<string> {
        let s = new Set(this.rawData.map(x => x.location));
        return Array.from(s);
    }

    get allRegions(): Array<string> {
        return Object.keys(LOCATION_MAP_REGION);
    }

    get colorMap() {
        return COLOR_MAP;
    }
}

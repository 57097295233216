import React, {Component} from 'react';
import './DataTable.css';
import {Datum, DataStore, TransformedDatum} from "./Data";
import DataTable, {TableColumn} from 'react-data-table-component';

interface DatTableProps {
    dataStore: DataStore;
}

interface DatTableState {
    raw: boolean
}

export default class DatTable extends Component<DatTableProps, DatTableState> {
    rawDataColumns: Array<TableColumn<Datum>>;
    transformedDataColumns: Array<TableColumn<TransformedDatum>>;

    constructor(props: DatTableProps) {
        super(props);
        console.log("[DatTable-constructor]");
        this.state = {
          raw: false,
        };
        this.rawDataColumns = this.props.dataStore.rawDataColumns.map(x=>({
            name: x,
            selector: (row: any) => row[x],
            sortable: ["date", "location"].indexOf(x) >= 0,
        }));
        this.transformedDataColumns = this.props.dataStore.transformedDataColumns.map(x=>({
            name: x,
            selector: (row: any) => row[x],
            format: x === 'case_fatality_rate' ? (row, index) => row.case_fatality_rate?.toFixed(5)+'%' : undefined,
            sortable: true,
        }));
    }

    render() {
        return (
            <div style={{width: "100%", marginBottom: '3rem', marginTop: '3rem'}}>
                <h2>COVID-19 Data</h2>
                {this.state.raw ? (
                    <DataTable
                        title="COVID-19 RawData"
                        columns={this.rawDataColumns}
                        data={this.props.dataStore.rawData}
                        pagination
                        fixedHeader
                        persistTableHead
                        dense
                        highlightOnHover
                        striped
                        responsive
                    />
                ) : (
                    <DataTable
                        title="COVID-19 LatestDayData"
                        columns={this.transformedDataColumns}
                        data={this.props.dataStore.latestDayData}
                        pagination
                        fixedHeader
                        persistTableHead
                        dense
                        highlightOnHover
                        striped
                        responsive
                    />
                )}
                <label htmlFor="input-dt-raw">Show Raw Data</label>
                <input id="input-dt-raw" type="checkbox" name="input-dt-raw" onChange={(checked)=>{this.setState({raw: checked.target.checked});}}/>
            </div>
        );
    }
}

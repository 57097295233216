import React, {Component} from 'react';
import './App.css';
import Papa from 'papaparse';

import {DataStore, RawDatum} from "./Data";
import DataTable from "./DataTable";
import Scatter from "./Scatter";

const datafile = 'full_data-1.csv';

interface AppProps {
}

interface AppState {
    filePath: string
    dataStore: DataStore | null,
    errMsg: string,
}

export default class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        console.log("[App-constructor]");
        this.state = {
            filePath: process.env.PUBLIC_URL + "/" + datafile,
            dataStore: null,
            errMsg: "",
        }
    }

    componentDidMount() {
        this.readCsvData(this.state.filePath)
            .then(df => {
                this.setState({dataStore: df});
                return df as DataStore
            })
            .then((data: DataStore | null) => {
                console.log("All Date:", data?.rawDataAllDate);
                console.log("All Locations:", data?.allLocations);
                console.log("All Regions:", data?.allRegions);
            })
            .catch(err => this.setState(state => ({errMsg: state.errMsg + err.message + "\n"})));
    }

    async readCsvData(datafile: string) {
        console.log("[App-readCsvData] datafile:", datafile);

        let fetchResponse = await fetch(datafile);
        console.log("[App-readCsvData] fetchResponse", fetchResponse);

        let rstream: ReadableStream<Uint8Array> | null = fetchResponse?.body;
        if (rstream == null) {
            throw new Error("No Data File")
        }
        console.log("[App-readCsvData] rstream", rstream);

        const decoder = new TextDecoder('utf-8');
        let csvText = "";
        let done = false;
        let reader = rstream.getReader();
        let count = 0;
        while (!done) {
            let encodedObj = await reader.read();
            console.log("[App-readCsvData] encodedObj_" + (count++), encodedObj);
            done = done || encodedObj.done;
            csvText = csvText + decoder.decode(encodedObj.value);
        }
        if (csvText.endsWith('\n')) {
            csvText = csvText.slice(0, csvText.length - 1);
        }
        console.log("[App-readCsvData] encodedObj] csvTest >>>>>")
        console.log(csvText);
        console.log("[App-readCsvData] encodedObj] <<<<<")
        const csvObj = Papa.parse(csvText, {header: true});
        console.log("[App-readCsvData] encodedObj] csvObj", csvObj)
        if (csvObj.errors.length > 0) {
            let msgString = csvObj.errors.map(x => JSON.stringify(x)).reduce((prev: string, cur: string) => prev + "\n" + cur);
            throw new Error(msgString);
        }
        return new DataStore(csvObj.data as Array<RawDatum>);
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <span>
                        <h4>HKUST 2022S - MSBD5005 HW2</h4>
                        <h4>Koo Tin Lok (20344775)</h4>
                        <p>This assignment visualize the COVID-19 data from <a
                            href="https://github.com/owid/covid-19-data/tree/master/public/data/jhu">owid/covid-10-data</a>, who get data from <a
                            href="https://ourworldindata.org/coronavirus">Our World in Data</a></p>
                        <p>The data contains 229 locations (215 countries + 6 continents + 8 others ["European Union", "High Income", "Upper middle income", "Lower middle Income", "Low Income", "International", "World", "Summer Olympics 2020"])</p>
                        <p>The data date range from 2020-01-22 to 2022-01-21 (731 days)</p>
                        <p>Here we analysis the data that is on the latest available date (2022-01-21)</p>
                        <p>Remarks: For better visualization, the locations are separated into regions using the convention in United Nation and can be found <a
                            href="https://statisticstimes.com/geography/countries-by-continents.php">here</a>.</p>
                        <p>Remarks: The non-countries location are classified as "NA" group, it is advised to filter out NA</p>
                    </span>
                    {
                        this.state.errMsg && (
                            <div className="error-display">
                                <pre>
                                    <h4>Error Message:</h4>
                                    {this.state.errMsg || "None"}
                                </pre>
                            </div>
                        )
                    }
                </header>
                {this.state.dataStore && (
                    <div className="data" style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <div style={{flex: 1}}>
                            <Scatter dataStore={this.state.dataStore} />
                        </div>
                        <div style={{flex: 1}}>
                            <DataTable dataStore={this.state.dataStore}/>
                        </div>

                    </div>
                )}
            </div>
        );
    }
}

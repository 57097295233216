import React, {Component} from 'react';
import './DataTable.css';
import {DataStore} from "./Data";
import ReactECharts from 'echarts-for-react';
import { Payload } from 'echarts';

interface ScatterProps {
    dataStore: DataStore;
}

interface ScatterState {
    loaded: boolean,
    showLabel: boolean,
    noNA: boolean,
    naOnly: boolean,
}

export default class Scatter extends Component<ScatterProps, ScatterState> {
    private ecRef: ReactECharts | null;

    constructor(props: ScatterProps) {
        super(props);
        console.log("[Scatter-constructor]");
        this.state = {
            loaded: false,
            showLabel: true,
            noNA: false,
            naOnly: false,
        }
        this.ecRef = null;
    }

    getOptions() {
        let options = {
            title: {
                text: 'COVID-19 Scatter Plot',
                subtext: 'By Koo Tin Lok 20344775',
                left: 'center'
            },
            legend: {
                data: this.props.dataStore.allRegions,
                right: 0,
                orient: 'vertical',
                top: 'center'

            },
            xAxis: {
                type: "value",
                name: "Number of Case",
                nameLocation: "center",
            },
            yAxis: {
                type: "value",
                name: "Number of Death",
            },
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                },
                {
                    type: 'inside',
                    yAxisIndex: 0,
                    filterMode: 'empty',
                },
                {
                    type: "slider",
                    xAxisIndex: 0,
                    bottom: 'auto',
                },
                {
                    type: "slider",
                    yAxisIndex: 0,
                    left: 0,
                },
            ],
            grid: {
                width: 700,
            },
            tooltip: {
                showDelay: 0,
                axisPointer: {
                    show: true,
                    type: 'cross',
                    lineStyle: {
                        type: 'dashed',
                        width: 1
                    }
                },
                formatter: (params: any) => {
                    return `Region: ${params.data._region}<br/>` +
                        `Location: ${params.data.location}<br/>` +
                        `Total cases: ${params.data.total_cases}<br/>` +
                        `Total deaths: ${params.data.total_deaths}` +
                        `Case-fatality rate: ${(params.data.case_fatality_rate).toFixed(2)}%<br/>` +
                        `[Date ${params.data.date}]`;
                }
            },
            toolbox: {
                show: true,
                feature: {
                    dataView: {
                        readOnly: true
                    },
                    restore: {},
                    saveAsImage: {},
                }
            },
            dataset: ([
                {
                    source: this.props.dataStore.latestDayData,
                    dimension: ["location", "date", "total_cases", "total_deaths", "case_fatality_rate", "_region", "_color"],
                },
            ] as Array<any>).concat(this.props.dataStore.allRegions.map(x => ({
                transform: {
                    type: 'filter',
                    config: {dimension: '_region', value: x},
                    print: true,
                }
            }))),
            series: this.props.dataStore.allRegions.map((x, i) => {
                return {
                    type: "scatter",
                    name: x,
                    emphasis: {
                        focus: 'series'
                    },
                    coordinateSystem: 'cartesian2d',
                    legendHoverLink: true,
                    datasetIndex: i + 1,
                    dimensions: ["total_cases", "total_deaths"],
                    tooltip: {
                        show: true,
                        trigger: "item",
                    },
                    colorBy: 'series',
                    color: this.props.dataStore.colorMap[x],
                    label: {
                        show: this.state.showLabel,
                        position: 'right',
                        distance: 5,
                        formatter: (params: any) => params.data.location,
                    }
                };
            }),
        };
        if (this.state.naOnly){
            options.series = options.series.slice(options.series.length - 1);
        }
        if (this.state.noNA) {
            options.series = options.series.slice(0, options.series.length - 1);
        }

        console.log(options);
        return options
    }

    _dispatch(options: Payload){
        return true;
    }
    showLabel(checked: boolean){
        this.setState({showLabel: checked});
    }

    noNA(checked: boolean) {
        this.setState({noNA: checked});
    }

    naOnly(checked: boolean){
        this.setState({naOnly: checked, noNA: checked ? false : this.state.noNA});
    }

    render() {
        return (
            <div style={{marginBottom: '3rem', marginTop: '3rem'}}>
                <h2>COVID-19 Case-Death Scatter plot</h2>
                <h5>Config</h5>
                <div>
                    <label htmlFor="input-sp-label">Show Label</label>
                    <input id="input-sp-label" type="checkbox" name="input-sp-label" checked={this.state.showLabel} onChange={(checked)=>{this.showLabel(checked.target.checked)}}/>
                    <span> </span>
                    <label htmlFor="input-sp-nona">Filter Out NA</label>
                    <input id="input-sp-nona" type="checkbox" name="input-sp-nona" checked={this.state.noNA} onChange={(checked)=>{this.noNA(checked.target.checked)}}/>
                    <span> </span>
                    <label htmlFor="input-sp-naonly">Show only NA</label>
                    <input id="input-sp-naonly" type="checkbox" name="input-sp-naonly" checked={this.state.naOnly} onChange={(checked)=>{this.naOnly(checked.target.checked)}}/>
                </div>
                <div style={{width: "100%", height: 800}}>
                    <ReactECharts
                        ref={(e) => { this.ecRef = e; }}
                        option={this.getOptions()}
                        onChartReady={() => {
                            this.setState({loaded: true})
                        }}
                        notMerge={true}
                        lazyUpdate={false}
                        opts={{locale: 'EN', width: 1000, height: 800}}
                    />
                </div>
            </div>
        );
    }
}
